








/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import Community from '@/models/graphql/Community';
import IllustrationType from '@/utils/enums/IllustrationType';
import MessagePage from '@/views/guest/MessagePage.vue';
import CommunityUser from '@/models/graphql/CommunityUser';
import ErrorPageType from '@/utils/enums/ErrorPageType';

const communityUserStore = namespace('CommunityUserStore');

@Component({
  components: { MessagePage },
})
export default class ConfirmationEmailPage extends Vue {
  @Getter
  community!: Community;

  @Prop({ required: false, default: '' })
  token!: string;

  @Getter
  authUser!: CommunityUser

  @communityUserStore.Action
  private changeUserEmail!: (
    payload: {
      uploadToken: string;
      userUid: string;
    }
  ) => Promise<Response>;

  private pageType = '';

  private IllustrationType = IllustrationType;

  private ErrorPageType = ErrorPageType;

  mounted(): void {
    if (!this.authUser) {
      this.$router.replace({ name: 'login' });
    } else if (this.token && this.token !== '') {
      const parsedToken = this.parseJwt(this.token);
      let userUid = '';
      if (parsedToken) {
        userUid = parsedToken.u ? parsedToken.u : '';
      }
      if (userUid === this.authUser.uid) {
        this.changeUserEmail({
          uploadToken: this.token,
          userUid: this.authUser.uid,
        }).then((response) => {
          if (response) {
            this.pageType = '';
            this.$router.push({ path: '/' });
          } else {
            this.pageType = ErrorPageType.INVALID_TOKEN;
          }
        })
          .catch(() => {
            this.pageType = ErrorPageType.INVALID_TOKEN;
          });
      }
    } else {
      this.pageType = ErrorPageType.INVALID_TOKEN;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseJwt(token: string): {c: string; e: string; exp: number; iat: number; u: string} | null{
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+')
      .replace(/_/g, '/');
    try {
      const jsonPayload = decodeURIComponent(atob(base64).split('')
        .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
      return JSON.parse(jsonPayload);
    } catch (e) {
      this.pageType = 'invalid-token';
    }
    return null;
  }
}
